<template>
    <div>
        <div id="app" class="container mt-5">
        <div class="card">
            <div class="card-body text-center">
                <h2 class="card-title">支付成功！</h2>
                <p class="card-text">您的订单已支付成功，感谢您的购买。</p>
                <p class="card-text">订单号：{{ this.token }}</p>
                <span>扣款订单号{{ this.captureGetUrl }}</span><br>
                <button @click="capturesGet()">查看扣款信息（后端）</button><br>
                <button @click="ordersGet()">查看订单信息（后端）</button>
            </div>
        </div>
    </div>
    </div>
</template>


<script>
export default {
    name: "Orders",
    data() {
        return {
            user: localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {},
            token: '',
            captureGetUrl: '',
        }
    },
    created() {
       this.payPalCapture()
       this.ordersGet()
    },
    methods: {
        payPalCapture() {
            //const searchParams = new URLSearchParams(window.location.search);
            //this.token = searchParams.get('token');
            this.token = this.$route.query.token;
            console.log(this.token);
            this.request.get('/payPalSuccess/' + this.token).then(res => {
                this.captureGetUrl = res.captureId
                console.log(res.captureId);
            })
            
        },
        capturesGet(){
            this.request({
                url: '/capturesGet',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    captureGetUrl: this.captureGetUrl,
                }
            }).then((res)=> {
                console.log(res);
            })
        },

        ordersGet(){
            this.request({
                url: '/ordersGet',
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                params: {
                    orderId: this.token,
                }
            }).then((res)=> {
                console.log(res);
            })
        }
    }
}
</script>

<style scoped>

</style>